import { PUBLIC_SENTRY_ENV } from "$env/static/public";
import { handleErrorWithSentry, init as initSentry } from "@sentry/sveltekit";

initSentry({
  dsn: 'https://92e40bb138173823da52540976b0c4f2@o4507029683109888.ingest.us.sentry.io/4507029686190085',
  environment: PUBLIC_SENTRY_ENV,
  tracesSampleRate: 1.0,  
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();

