import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [0,2,3,5];

export const dictionary = {
		"/": [~6],
		"/404": [7],
		"/auth/invite": [9],
		"/bundles": [~10],
		"/bundles/[bundle]": [11,[2]],
		"/courses": [~12],
		"/courses/[course]": [~13,[3]],
		"/courses/[course]/lessons": [14,[3]],
		"/courses/[course]/review": [15,[3]],
		"/pay": [16],
		"/privacy": [~17],
		"/purchase-success": [~18],
		"/puzzles": [~19,[4]],
		"/puzzles/[puzzle]": [~20,[4]],
		"/terms": [~21],
		"/worksheets": [~22],
		"/worksheets/[worksheet]": [23,[5]],
		"/worksheets/[worksheet]/review": [~24,[5]],
		"/[content_page]": [~8]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';